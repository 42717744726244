import React from 'react';
import { motion } from 'framer-motion';
import './stylesheet/About.css';
import Card from './card.js';
import github_img from './images/github-logo.png';
import linkedin_img from './images/linkedin-logo.png';
import headshot_img from './images/headshot/headshot.png';

function About({ navigateToSection }) {
    const textAnimation = {
        hidden: { clipPath: 'inset(0 100% 0 0)' },
        visible: {
            clipPath: 'inset(0 0 0 0)',
            transition: { duration: 4, delay: 0.25 },
        }
    };

    let descriptionHTML = "Computer Engineering Student<br />Mathematics and Statistics Student<br />IBM Data Science Certification<br />Google Data Analytics Certification";
    let descriptionElements = descriptionHTML.split('<br />').map((item, index) => <p key={index}>{item}</p>);

    const icons = [
        { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho", download: "" },
        { src: linkedin_img, alt: "linkedin", link: "https://www.linkedin.com/in/siyung-cho-04b046207/", download: "" },
      ];

    return (
        <div className="about-container" style={{'height': '670px'}}>
            <div className="about-header">
                <button onClick={() => navigateToSection('projects')}>
                    <span className="actual-text">1. Projects</span>
                    <span className="hover-text" aria-hidden="true">1.&nbsp;Projects</span>
                </button>
                <button onClick={() => navigateToSection('experience')}>
                    <span className="actual-text">2. Experience</span>
                    <span className="hover-text" aria-hidden="true">2.&nbsp;Experience</span>
                </button>
                {/*<button onClick={() => navigateToSection('contact')}>
                    <span className="actual-text">3. Contact Me</span>
                    <span className="hover-text" aria-hidden="true">3.&nbsp;Contact&nbsp;Me</span>
                </button>*/}
            </div>

            <div className='about-main-container'>

                <motion.div
                    className="about-text"
                    initial="hidden"
                    animate="visible"
                    variants={textAnimation}
                >
                    <h1 className='about-title'>.Frank Siyung Cho</h1>
                    <p className='about-description'>
                    Once set on a healthcare path, my world changed in Grade 11 when a computer science class — and a particularly inspiring teacher — sparked a passion for technology! Nowadays, I'm a computer engineering student at Queen’s University, with a special focus on data science and machine learning, though I’m equally captivated by full-stack and software development. Outside of classes, I love to lead projects as a project manager for QMIND, Canada’s Largest AI Association, pushing the boundaries of what the world and us students can achieve. I'm currently seeking internships for 2024 to put all this theory into practice. Thanks for stopping by my site and I hope you catch a glimpse of my journey in tech and everything i've worked on!
                    </p>
                </motion.div>

                <div className='about-more-info'>

                    <motion.div
                        className="about-more-info-container"
                        initial="hidden"
                        animate="visible"
                        variants={textAnimation}
                    >
                    <Card img={headshot_img} title={"Queen's U 3rd Year Student"} description={descriptionElements} icons={icons} date={'20fsc@queensu.ca'} cardSelection='aboutcard-preview' card_image='about-image' />
                    </motion.div>

                </div>

            </div>

        </div>
    );
}

export default About;
