import './stylesheet/Projects.css';
import './stylesheet/header.css';
import React from 'react';
import Card from './card.js';
import ProjectTable from './table.js';
import github_img from './images/github-logo.png';
import report_img from './images/report-icon.png';
import www_img from './images/www_icon.png';
import retinareport from './images/retinaradar/DR_Detection_Paper.pdf';
import lungreport from './images/other_projects/LungCancer_report.pdf';
import mineralreport from './images/other_projects/mineral_report.pdf';
import arbbet from './images/arbbet/arbbet.png';
import retinaradar from './images/retinaradar/retinaradar.png';
import storybookml from './images/storybookml/storybookml.png';

const columns = [
  {
    Header: 'DATE',
    accessor: 'date',
    style: { width: '20%' }, // adjust as needed
  },
  {
    Header: 'PROJECT TITLE',
    accessor: 'title',
    style: { width: '20%' }, // adjust as needed
  },
  {
    Header: 'TECH STACK',
    accessor: 'techStack',
    Cell: ({ value }) => <div style={{ width: '100%', wordWrap: 'normal' }}>{value}</div>,
  },
  {
    Header: 'LINKS',
    accessor: 'links',
    Cell: ({ value }) => (
      <div className="table-card-icons">
        {value.map((icon, index) => (
          <a key={index} href={icon.link} download={icon.download} target="_blank" rel="noopener noreferrer">
            <img src={icon.src} alt={icon.alt} className="table-card-image-icon" />
          </a>
        ))}
      </div>
    ),
  },
];
  
const data = [
  {
    date: 'Sep 2023 - Mar 2024',
    title: 'Retina Radar (QMIND 2024)',
    techStack: 'JS, React, Python, Django3, AWS EC2, AWS S3, Heroku',
    links: [
            { src: www_img, alt: "Web Application", link: "https://www.retinaradar.com" , download: ""}, //web app
            { src: report_img, alt: "Research Paper", link: retinareport, download: "DR Detection Research Paper" }, //research paper
            { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho/Diabetic-Retinopathy-Detection/tree/siyung" , download: ""}, //git repo
          ],
  },
  {
    date: 'Feb 2024',
    title: 'Story Book ML (QHacks 2024)',
    techStack: 'JS, ReactNative, Python, OpenAI API, Flask, AWS S3, Heroku',
    links: [
      { src: www_img, alt: "Web Application", link: "https://storybookml.tech" }, //web app
      { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho"}, //git repo
    ]
  },
  {
    date: 'Feb 2024 - Current',
    title: 'Arbitrage Sportsbook',
    techStack: 'MySQL, React, Python, Scrapy, Django3, AWS EC2, Tableau',
    links: [  { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho"}  ], //git repo
  },
  {
    date: 'Jul 2023 - Sep 2023',
    title: 'NFL Combine Predictive Analysis',
    techStack: 'MySQL, React, Python, Django3, Selenium4, AWS S3, Heroku',
    links: [
      { src: www_img, alt: "Web Application", link: "https://www.probowlprospectmetrics.com/" , download: ""}, //web app
      { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho/Scrape-NFL-Combine" , download: ""}, //git repo
    ],
  },
  {
    date: 'Dec 2023',
    title: 'SpaceX Falcon9 Launch (IBM Certification)',
    techStack: 'SQL, Python, BS4, Plotly Dash, Folium',
    links: [  { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho/IBM_DataScience_Capstone"}  ], //git repo
  },
  {
    date: 'Jan 2024',
    title: 'Fitbit Case Study (Google Certification)',
    techStack: 'SQL, Tableau, R, Python, GCP',
    links: [  { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho"}  ], //git repo
  },
  {
      date: 'Sep 2022 - Mar 2023',
      title: 'Lung Cancer Detection (QMIND 2023)',
      techStack: 'Python, PyTorch, TensorFlow',
      links: [
        { src: report_img, alt: "Research Paper", link: lungreport, download: "Lung Cancer Detection Research Paper" }, //research paper
      ],
    },
  {
      date: 'Jan 2023',
      title: 'NYC Housing Market Analysis',
      techStack: 'Python, SKLearn',
      links: [  { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho/NYC_Housing_Analysis"}  ], //git repo 
    },
  {
    date: 'July 2023',
    title: 'Online To Do list Web App',
    techStack: 'SQLite, Python, Django3',
    links: [
      { src: www_img, alt: "Web Application", link: "https://www.todonenow.com" , download: ""}, //web app 
      { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho/django-todo-list-website" , download: ""}, //git repo 
    ],
  },
  {
    date: 'Nov 2023',
    title: 'Mineral Deposit Predictive Analysis',
    techStack: 'Python, SKLearn',
    links: [
      { src: report_img, alt: "Research Paper", link: mineralreport, download: "Predictive Mineral Deposits Research Paper" }, //research paper
    ],
  },
];

let descriptionHTML_spot1 = "QMIND 2024 Project: A Deep Learning approach to identifying early-onset diabetes. Top 8 team out of over 70 teams at competing at CUCAI 2024. Published and Showcased results to guests from Apple, Intel, OpenAI and more.";
let descriptionElements_spot1 = descriptionHTML_spot1.split('<br />').map((item, index) => <p key={index}>{item}</p>);

const icons_spot1 = [
  { src: www_img, alt: "Web Application", link: "https://www.retinaradar.com" , download: ""}, //web app
  { src: report_img, alt: "Research Paper", link: retinareport, download: "DR Detection Research Paper" }, //research paper
  { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho/Diabetic-Retinopathy-Detection/tree/siyung" , download: ""}, //git repo
];

let descriptionHTML_spot2 = "QHacks 2024 Project: A Deep Learning web app that generates learning resources for disabled children through storytelling. Placed top 5 in education based projects. Showcased results to guests from CAC, Distributive, CPP investments and more.";
let descriptionElements_spot2 = descriptionHTML_spot2.split('<br />').map((item, index) => <p key={index}>{item}</p>);

const icons_spot2 = [
  { src: www_img, alt: "Web Application", link: "https://storybookml.tech" }, //web app
  { src: github_img, alt: "Git Repo", link: "https://github.com/SiyungCho"}, //git repo
];

let descriptionHTML_spot3 = "Personal Project: A Deep Learning approach to creating a unified sportsbook web app that scrapes sportsbook odds and calculates arbitrage opportunities. Using Graph Neural Networks to optimize for data traversal.<br />Currently in development.";
let descriptionElements_spot3 = descriptionHTML_spot3.split('<br />').map((item, index) => <p key={index}>{item}</p>);

const icons_spot3 = [
  { src: github_img, alt: "personal github", link: "https://github.com/SiyungCho"},
];

function Projects() {
  return (
    <div className="projects-container" style={{'height': '1300px'}}>
        {/* Header Section */}
        <div className="section-header">
            <div><span className="section-hover-text" aria-hidden="true">1.&nbsp;Projects</span></div>
            <div><span className="section-hover-text section-hide">2.&nbsp;Experience</span></div>
            {/*<div><span className="section-hover-text section-hide">3.&nbsp;Contact&nbsp;Me</span></div>*/}
        </div>

        {/* Spotlight Projects Section */}
        <div className="spotlight-projects">

            <Card img={retinaradar} title={'Retina Radar'} description={descriptionElements_spot1} icons={icons_spot1} date={'◷ March 2024'}/>
            <Card img={storybookml} title={'Story Book ML'} description={descriptionElements_spot2} icons={icons_spot2} date={'◷ Feb 2024'}/>
            <Card img={arbbet} title={'Arbitrage Sportsbook'} description={descriptionElements_spot3} icons={icons_spot3} date={'◷ Current'}/>
            <div className="project-spotlight-preview">
                <div className="project-spotlight-preview-header">
                    <span>SPOTLIGHT</span>
                </div>
                {/*<div className="project-spotlight-preview-description">
                    <span>DESCRIPTION</span>
                  </div>*/}
            </div>
        </div>

        {/* Project Details Section */}
        <div className="other-projects-table-container">
            <ProjectTable columns={columns} data={data} />
        </div>


    </div>
  );
}

export default Projects;
