import React, { useRef, useEffect, useState } from 'react';
import './stylesheet/header.css';
import './stylesheet/Minigame.css';

function Minigame() {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const ws = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [prompt, setPrompt] = useState('apple');
  const [prediction, setPrediction] = useState('');
  const [gameStarted, setGameStarted] = useState(false);

  const prompts = ['apple', 'banana', 'bicycle', 'butterfly', 'camel', 'camera', 'car', 'clock', 'cloud', 'cookie', 'guitar', 'house', 'light bulb', 'octopus', 'paper clip', 'scissors', 'shoe', 'shorts', 'snowman', 'star', 'stop sign', 'sun', 't-shirt', 'television', 'tennis racquet', 'tree'];

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 400;
    canvas.height = 400;
    canvas.style.width = `400px`;
    canvas.style.height = `400px`;

    const context = canvas.getContext('2d');
    context.fillStyle = "black";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.lineCap = "round";
    context.strokeStyle = "white";
    context.lineWidth = 10;
    contextRef.current = context;
  }, []);

  const startGame = () => {
    {/*ws.current = new WebSocket('ws://127.0.0.1:8000/ws/backend/');*/}
    ws.current = new WebSocket('wss://www.siyungcho.com/ws/backend/');
    ws.current.onopen = () => {
      console.log('WebSocket Connected');
      setGameStarted(true);
    };
    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      setPrediction(data.prediction);
    };
    ws.current.onclose = () => console.log('WebSocket Disconnected');
  };

  useEffect(() => {
    if (isDrawing && prediction !== prompt) {
      const interval = setInterval(() => {
        sendCanvasImage();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isDrawing, prediction]);

  const startDrawing = ({nativeEvent}) => {
    if (prediction === prompt) return; // Stop drawing if correct prediction was made
    const {offsetX, offsetY} = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
    if (prediction !== prompt) {
      sendCanvasImage();
    }
  };

  const draw = ({nativeEvent}) => {
    // Draw on canvas while mouse is moving
    if (!isDrawing) {
      return;
    }
    const {offsetX, offsetY} = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  const sendCanvasImage = () => {
    const canvas = canvasRef.current;
    canvas.toBlob(blob => {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(blob);
      }
    }, 'image/png');
  };

  const clearCanvas = () => {
    const context = contextRef.current;
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.fillStyle = "black";
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const resetGame = () => {
    clearCanvas();
    const newPrompt = prompts[Math.floor(Math.random() * prompts.length)];
    setPrompt(newPrompt);
    setPrediction(''); // Reset the prediction so the game can start making predictions again
  };

  return (
    <div className="minigame-container" style={{ height: '700px' }}>
      <div className="section-header">
        <div><span className="section-hover-text section-hide">&nbsp;&nbsp;</span></div>
        <div><span className="section-hover-text" aria-hidden="true">Bonus</span></div>
        <div><span className="section-hover-text section-hide">&nbsp;&nbsp;</span></div>
      </div>

      <div className='minigame-main-container'>
        <div className='minigame-instructions'>
          <h4>Instructions</h4>
          <p>Draw the prompt in the canvas. The AI will try to guess what you drew. If the AI guesses correctly, you win!</p>
          <p><b>Prompt: Draw a {prompt}</b></p>
          <p style={{ color: prediction === prompt ? '#37FF8B' : '#ccd6f6' }}><b>AI Prediction: {prediction}</b></p>
          {
            prediction === prompt && 
            <>
              <p style={{ color:'#37FF8B'}}><b>Congrats, the AI guessed correctly!</b></p>
              <p style={{ color:'#37FF8B'}}><b>Please press New Prompt to play again.</b></p>
            </>
          }
        </div>

        <div className='minigame'>
          <canvas
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={finishDrawing}
            ref={canvasRef}
            style={{ display: gameStarted ? 'block' : 'none' }}
          />
          <div className="game-controls">
            {!gameStarted && <button onClick={startGame}>Start Game</button>}
            <button onClick={clearCanvas} style={{ display: gameStarted ? 'block' : 'none' }}>Clear</button>
            <button onClick={resetGame} style={{ display: gameStarted ? 'block' : 'none' }}>New Prompt</button>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Minigame;
