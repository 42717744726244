import './stylesheet/Experience.css';
import './stylesheet/header.css';
import React from 'react';

import ProjectTable from './table.js';

const columns = [
  {
    Header: 'DATE',
    accessor: 'date', // accessor is the "key" in the data
  },
  {
    Header: 'COMPANY',
    accessor: 'company',
  },
  {
    Header: 'POSITION',
    accessor: 'position',
  },
  {
    Header: 'RESPONSIBILITIES',
    accessor: 'responsibilities',
  },
];

const data = [
  {
    date: 'May 2023 - March 2024',
    company: 'QMIND',
    position: 'Technical Project Manager',
    responsibilities: 'Led team of university students in ML based healthcare project',
  },
  {
    date: 'Summer 2023',
    company: 'Daldongnae Restaurant',
    position: 'Server',
    responsibilities: 'Managed high-stress setting via time management and leadership',
  },
  {
    date: 'Summer 2023 & 2022',
    company: 'VIP Karaoke Bar',
    position: 'Bartender',
    responsibilities: 'Multitasked customers and providing detail-oriented service',
  },
  {
    date: 'Sep 2022 - May 2023',
    company: 'QMIND',
    position: 'Data Scientist',
    responsibilities: 'Developed project to classify lung cancer from X-ray scans',
  },
  {
    date: 'Sep 2022 - May 2023',
    company: 'QBMET',
    position: 'Data Analyst',
    responsibilities: 'Analyzed censor data to optimize exoskeleton movements',
  },
  {
    date: 'Summer 2022',
    company: 'Glebe Community Centre',
    position: 'Mathematics Tutor',
    responsibilities: 'Tutored students in Glebe Community in Mathematics',
  },
  {
    date: 'June 2020 - Sep 2021',
    company: 'Chipotle Mexican Grill',
    position: 'Grill Cook, Staff Member',
    responsibilities: 'Ensured food safety and quality in high-stress environment',
  },
];

function Experience() {
  return (
    <div className="experience-container" style={{'height': '800px'}}>
      {/* Header Section */}
      <div className="section-header">
        <div><span className="section-hover-text section-hide">&nbsp;&nbsp;</span></div>
        <div><span className="section-hover-text" aria-hidden="true">2.&nbsp;Experience</span></div>
        {/*<div><span className="section-hover-text section-hide">&nbsp;&nbsp;</span></div>*/}
      </div>

      {/* Experience Details Section */}
      <div className="experience-table-wrapper-container">
            <ProjectTable columns={columns} data={data} section='experience'/>
      </div>
    </div>
  );
}

export default Experience;