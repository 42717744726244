import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import About from './components/About';
import Projects from './components/Projects';
import Experience from './components/Experience';
//import ContactMe from './components/Contact';
import Minigame from './components/MiniGame';

function App() {
  const [currentSection, setCurrentSection] = useState('about');
  const sectionRefs = {
    about: useRef(null),
    projects: useRef(null),
    experience: useRef(null),
    contact: useRef(null),
    minigame: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const projectsTop = sectionRefs.projects.current?.getBoundingClientRect().top;
      const experienceTop = sectionRefs.experience.current?.getBoundingClientRect().top;
      //const contactTop = sectionRefs.contact.current?.getBoundingClientRect().top;
      const minigameTop = sectionRefs.minigame.current?.getBoundingClientRect().top;
  
      if (scrollPosition < 100) {
        setCurrentSection('about');
      } else if (minigameTop <= window.innerHeight - 200) {
        setCurrentSection('minigame');
      //} else if (contactTop <= window.innerHeight - 200) {
        //setCurrentSection('contact');
      } else if (experienceTop <= window.innerHeight - 100) {
        setCurrentSection('experience');
      } else if (projectsTop <= window.innerHeight - 100) {
        setCurrentSection('projects');
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigateToSection = (section) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const cursorGlowEffect = (e) => {
      const glow = document.querySelector('.cursor-glow');
      glow.style.left = e.clientX + "px";
      glow.style.top = e.clientY + "px";
    };

    window.addEventListener('mousemove', cursorGlowEffect);

    return () => {
      window.removeEventListener('mousemove', cursorGlowEffect);
    };
  }, []);


  return (
    <Router>
      <div className="App">
        <div className="cursor-glow"></div>
        <div ref={sectionRefs.about} className={`section ${currentSection === 'about' ? 'active' : ''}`}><About navigateToSection={navigateToSection} /></div>
        <div ref={sectionRefs.projects} className={`section ${currentSection === 'projects' ? 'active' : ''}`}><Projects /></div>
        <div ref={sectionRefs.experience} className={`section ${currentSection === 'experience' ? 'active' : ''}`}><Experience /></div>

        {/*<div ref={sectionRefs.contact} className={`section ${currentSection === 'contact' ? 'active' : ''}`}><ContactMe /></div>*/}
        <div ref={sectionRefs.minigame} className={`section ${currentSection === 'minigame' ? 'active' : ''}`}><Minigame /></div>
      </div>
    </Router>
  );
}

export default App;
