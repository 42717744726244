import './stylesheet/card.css';
import React from 'react';

function Card({img, title, description, icons=[], date, cardSelection="project-preview", card_image="project-image"}) {
    return (
        <div className={`${cardSelection}`}>
            <img className={`${card_image}`} src={img} alt="default"/>
            <div className='project-text-container'>
                <h2>{title}</h2>
                <h4 className="card-description">{description}</h4>
                <hr />
            </div>
            <div className="card-info">
                <div className="card-icons">
                    {icons.map((icon, index) => (
                        <a key={index} href={icon.link} download={icon.download} target="_blank" rel="noopener noreferrer">
                            <img src={icon.src} alt={icon.alt} className="card-image-icon" />
                        </a>
                    ))}
                </div>
                <div className="card-date">
                    <div className="card-date-description">{date}</div>
                </div>
            </div>
        </div>
    );
}

export default Card;